// 'use strict';

// var mobileTouchLinkHandlingWithHoverEffect = function() {

//     var resetHoverState = function() {
//         var hoverLinks = document.querySelectorAll('.mobileTouchHover');
//         hoverLinks.forEach(function(link) {
//             link.dataset.hoverShown = 'false';
//         });
//     };

//     var onTouchStart = function(e) {
//         // if (e.target.classList.contains('mobileTouchHover')) {
//             if (this.dataset.hoverShown === 'true') {
//                 this.dataset.hoverShown = 'false';
//                 // window.location = e.target.href;
//                 return;
//             }

//             e.preventDefault();
//             e.stopPropagation();
//             this.dataset.hoverShown = 'true';
//         // }
//     }
	
//     window.addEventListener('pageshow', resetHoverState);
//     window.addEventListener('load', resetHoverState);
//     window.addEventListener('popstate', resetHoverState);
//     document.addEventListener('DOMContentLoaded', resetHoverState);

//     // document.addEventListener('touchstart', (e) => onTouchStart(e), { passive: false });
// 	var hoverLinks = document.querySelectorAll('.mobileTouchHover:not(.last-item__link)');
// 	hoverLinks.forEach(function(link) {
// 		link.addEventListener('touchstart', onTouchStart, { passive: false });
// 	});

//     var removeEventListeners = function() {
//         window.removeEventListener('pageshow', onPageshow);
//         window.removeEventListener('load', onLoad);
//         window.removeEventListener('popstate', onPopstate);
//         document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
//         document.removeEventListener('touchstart', (e) => onTouchStart(e), { passive: false });
//     };

//     return removeEventListeners;

// };

// var init = function() {
    
// 	mobileTouchLinkHandlingWithHoverEffect();

// };

// module.exports = {
// 	init: init
// };


'use strict';

var appDom = require('../../app-dom');

var mobileTouchLinkDelay = function() {
    var linkTouchStart = function(e) {
        var link = e.target.closest('.custom-link');

        e.preventDefault();
        e.stopPropagation();

        setTimeout(function() {
            window.location.href = link.href;
        }, 600);
    }

    var links = document.querySelectorAll('.image-animate a:not(.mobileTouchHover):not(.last-item__link)');
    links.forEach(function(link) {
		link.addEventListener('touchstart', linkTouchStart, { passive: false });
	});

    var removeEventListeners = function() {
        document.removeEventListener('touchstart', function(e) { linkTouchStart(e), { passive: false }});
    };

    return removeEventListeners;
}

var mobileTouchLinkHandlingWithHoverEffect = function() {

    var hoverLinks = document.querySelectorAll('.mobileTouchHover:not(.last-item__link)');

    var resetHoverState = function() {
        hoverLinks.forEach(function(link) {
            link.dataset.hoverShown = 'false';
        });
    };

    var onTouchStart = function(e) {
        if (this.dataset.hoverShown === 'true') {
            this.dataset.hoverShown = 'false';
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        this.dataset.hoverShown = 'true';
    }
	
    window.addEventListener('pageshow', resetHoverState);
    window.addEventListener('load', resetHoverState);
    window.addEventListener('popstate', resetHoverState);
    document.addEventListener('DOMContentLoaded', resetHoverState);

	hoverLinks.forEach(function(link) {
		link.addEventListener('touchstart', onTouchStart, { passive: false });
	});

    var removeEventListeners = function() {
        window.removeEventListener('pageshow', onPageshow);
        window.removeEventListener('load', onLoad);
        window.removeEventListener('popstate', onPopstate);
        document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
        document.removeEventListener('touchstart', function(e) { onTouchStart(e), { passive: false }});
    };

    return removeEventListeners;

};

var init = function() {
    
    // if (appDom.document.find('.block .custom-link:not([mobileTouchHover]):not(js-load-more-items)').length) {
    //     mobileTouchLinkDelay();
    // }

    // if (appDom.document.find('.mobileTouchHover').length) {
	//     mobileTouchLinkHandlingWithHoverEffect();
    // }

};

module.exports = {
	init: init
};
