'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('../app-dom');
var utils = require('../utils/main');
var images = require('./images/main');

var isLoading = false;

var onLoadMoreItemsClickDone = function($grid, prevNumItemsRendered, response) {

	// Always remove the load more item, if we still need it, we'll get another one in the response
	utils.dom.findLastItem($grid).remove();
	
	// Add the next batch to the dom
	$grid.append(response.markup);

	// If this block has been updated to support data-num-items-rendered, update it's value
	if ($grid.attr('data-num-items-rendered')) {

		$grid.attr('data-num-items-rendered', prevNumItemsRendered + response.numItemsRendered);

	}

	triggerCustomEvent('grid_items_added');

	images.init();
	isLoading = false;

};

var onLoadMoreItemsClick = function(e) {

	e.preventDefault();

	if(isLoading) return;

	isLoading = true;

	var $grid = utils.dom.closestGrid($(this));
	var numItemsRendered = parseInt($grid.attr('data-num-items-rendered'), 10);
	var $block = utils.dom.closestBlock($grid);
	var isMockedBlock = utils.dom.isMockedBlock($block);
	var data = {
		numItemsRendered: 0,
		blockFriendlyName: utils.dom.getBlockFriendlyName($block),
		drilldownBlockCount: pbizServer.drilldownBlockCount,
		isInPageContext: pbizServer.isInPageContext,
		pageFriendlyName: pbizServer.pageFriendlyName
	};

	// If this block hasn't been updated to support the data-num-items-rendered attribute, get the number by counting the grid cells
	if (!numItemsRendered) {

		// Subtract 1 because we don't want to count the load more item
		numItemsRendered = utils.dom.findCell($grid).not('.collage-layout__nested-grid-container').length - 1;

	}

	data.numItemsRendered = numItemsRendered;

	// Support load more for a mocked block on a system page
	if (isMockedBlock) {

		// Assuming that the only mocked blocks that support load more functionality are "pointer" blocks
		data.blockType = utils.dom.getBlockType($block);
		data.resourceTypeKey = utils.dom.getBlockResourceType($block);
		data.resourceIds = utils.dom.getBlockResourceIds($block);
		data.layoutRecordKey = utils.dom.getBlockLayoutKey($block);

	} else {

		data.blockId = utils.dom.getBlockId($block);

	}

	$.ajax({
		method: 'POST',
		url: utils.getUrl('block.loadMore'),
		data: data,
		dataType: 'json'
	}).done(function(response) {

		onLoadMoreItemsClickDone($grid, numItemsRendered, response);

	});

};

var addEventHandlers = function() {

	appDom.content.on('click', '.js-load-more-items', onLoadMoreItemsClick);

};

var init = function() {

	if (appDom.content.find('.js-load-more-items').length) {

		addEventHandlers();

	}

};

module.exports = {
	init: init
};
