'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var speed = .2;
var supportsIntersectionObserver = 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
// Store collection of images for all instances in order to only have 1 scroll handler
var $allImgs = $();
var observer;
var windowHeight;
var scrolling;
var scrollTimer;

var saveWindowHeight = function() {

	windowHeight = window.document.documentElement.clientHeight;

};

var saveContainerOffsetTop = function(img) {

	var rect = img.parentNode.getBoundingClientRect();

	// Cache the parent container offset so we don't have to get it each time we want to position the image

	// Save the container position relative to the top of the page
	$.data(img, 'containerOffsetTop', rect.top + window.pageYOffset);
	$.data(img, 'containerHeight', rect.height);

};

var position = function(img) {

	var scrollTop = window.pageYOffset;
	var imgData;
	var yPos;
	var isOnScreen;
	var viewableRangeEnd;
	// fixed parallax
	// var yPos = (containerOffsetTop - window.pageYOffset) * -1;

	if ($(img).css('display') === 'none') {

		return;

	}

	if (supportsIntersectionObserver) {

		isOnScreen = $.data(img.parentNode, 'isOnScreen');

	}

	if (typeof isOnScreen === 'undefined') {

		// Either this browser does not support IntersectionObserver, or it does, but the callback that sets the data hasn't been executed yet, so in that case use the fallback logic this time

		viewableRangeEnd = scrollTop + windowHeight;
		imgData = $.data(img);

		isOnScreen = imgData.containerOffsetTop < viewableRangeEnd && imgData.containerOffsetTop + imgData.containerHeight > scrollTop;

	}

	if (isOnScreen) {
		yPos = (scrollTop - (imgData ? imgData.containerOffsetTop : $.data(img, 'containerOffsetTop'))) * speed;

		img.style.transform = 'translate3d(0, ' + yPos + 'px, 0)';
		// img.style.marginTop = yPos + 'px';

	}

};

// Any time something happens that could affect the offset of the parent container (images or fonts loading, viewport resizing, content being added to the DOM, etc.), we'll need to re-cache the offets and re-position the images
var refresh = function($imgs) {

	saveWindowHeight();

	$imgs.each(function() {

		saveContainerOffsetTop(this);
		position(this);

	});

};

/*var onScroll = function() {

	var curScrollPos = window.pageYOffset;

	// Don't add another call to requestAnimationFrame to the stack unless the last call we made has already executed
	if (!pendingScrollRAF && lastScrollPos !== curScrollPos) {

		console.log('parallax onScroll...');

		pendingScrollRAF = true;
		lastScrollPos = curScrollPos;

		window.requestAnimationFrame(function() {

			pendingScrollRAF = false;

			$allImgs.each(function() {

				position(this);

			});

		});

	}

};*/

var stopScrolling = function() {

	scrolling = false;

};

var makeRequest = function() {

	if (scrolling) {

		window.requestAnimationFrame(function() {

			$allImgs.each(function() {

				position(this);

			});

			makeRequest();

		});

	}

};

var onScroll = function() {

	if (!scrolling) {

		scrolling = true;
		makeRequest();

	}

	if (scrollTimer) {

		window.clearTimeout(scrollTimer);

	}

	scrollTimer = window.setTimeout(stopScrolling, 100);

};

var toggleOnScreenFlag = function(entries/*, observer*/) {

	entries.forEach(function(entry) {

		$.data(entry.target, 'isOnScreen', entry.isIntersecting);

	});

};

// Based on the original container height, where on the page the container is, and the speed at which we are parallaxing, create a container height that is only as big as necessary to make sure we don't ever have whitespace

// NOTE: This algorithm assumes the scroll height of the page and the location of the container enable the page to be scrolled so that the container will always eventually hit the top or bottom of the viewport. This would be an issue, for example, if the last block on your page was a parallax text+image block, you would never be able to see the whole image, because you would hit the bottom of the page before the text+image block hits the top of the viewport. Or, if you had a non fullscreen parallax block at the top of your page, and your page was very short, so it only scrolls a little, you'd have the same issue.
var getContainerHeight = function(windowHeight, baseHeight, $img) {

	if (windowHeight === baseHeight) {

		return windowHeight;

	}

	return baseHeight + Math.min(windowHeight, $.data($img[0], 'containerOffsetTop') + baseHeight) * speed;


};

var add = function(state, $newImgs) {

	$newImgs.each(function() {

		var $img = $(this);

		// If InersectionObserver is not supported, simply default each parent container as visible
		if (supportsIntersectionObserver) {

			observer.observe(this.parentNode);

		}

		$img.css({
			'will-change': 'transform',
			'-webkit-backface-visibility': 'hidden',
			'backface-visibility': 'hidden'
		});

		saveContainerOffsetTop(this);
		position(this);

	});

	state.$imgs = state.$imgs.add($newImgs);

};

var onLoad = function() {

	refresh($allImgs);

};

var create = function() {

	var state = {
		$imgs: $()
	};

	return {
		add: function($newImgs) {

			if ($newImgs.length && !$allImgs.length) {

				// We're setting up the aggregate collection for the first time

				saveWindowHeight();

				// If supported, use IntersectionObserver to only move images if their parent container is visible within the viewport
				if (supportsIntersectionObserver) {

					observer = new IntersectionObserver(toggleOnScreenFlag);

				}

				domCache.window
					.on('scroll', onScroll)
					.on('load', onLoad);

			}

			$allImgs = $allImgs.add($newImgs);

			add(state, $newImgs);

		},
		refresh: function() {

			if (state.$imgs.length) {

				refresh(state.$imgs);

			}

		},
		getContainerHeight: getContainerHeight
	};

};

// Polyfill missing isIntersecting property for some browsers
if (supportsIntersectionObserver && !('isIntersecting' in window.IntersectionObserverEntry.prototype)) {

	Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
		get: function () {
			return this.intersectionRatio > 0;
		}
	});

}

module.exports = {
	create: create
};
