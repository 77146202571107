'use strict';

var appDom = require('../../app-dom');
var detail = require('./detail');

var init = function(/*actionItem*/) {

	// console.log('product-detail init');
	// console.log(appDom.document.find('.product-detail'));

	if (appDom.document.find('.product-detail').length) {

		// console.log('init the product detail stuff!');

		detail.init();

	}

};

module.exports = {
	init: init
};
