'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var utils = require('../utils/main');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var instanceMap = {};

var create = function($gallery, galleryId) {

	return instanceMap[galleryId] = new LoadMore($gallery, galleryId);

};

var get = function(galleryId) {

	return instanceMap[galleryId];

};

var LoadMore = function($gallery, galleryId) {

	this.$gallery = $gallery;
	this.galleryId = galleryId;

	// Listen for user interaction and then go get the rest of the items
	this.$gallery.on('gallery_thumb_changed.load-more gallery_item_changed.load-more', this.fetch.bind(this));

};

LoadMore.prototype.destroy = function() {

	instanceMap[this.galleryId] = null;

};

LoadMore.prototype.fetch = function() {

	var $block = utils.dom.closestBlock(this.$gallery);
	var isMockedBlock = utils.dom.isMockedBlock($block);
	var numItemsRendered = this.$gallery.attr('data-gallery-initial-items');

	var data = {
		numItemsRendered: numItemsRendered,
		blockFriendlyName: utils.dom.getBlockFriendlyName($block),
		drilldownBlockCount: pbizServer.drilldownBlockCount,
		isInPageContext: pbizServer.isInPageContext,
		pageFriendlyName: pbizServer.pageFriendlyName
	};

	this.$gallery.off('.load-more');

	// Support load more for a mocked block on a system page
	if (isMockedBlock) {

		data.blockType = utils.dom.getBlockType($block);
		data.resourceTypeKey = utils.dom.getBlockResourceType($block);
		data.resourceIds = utils.dom.getBlockResourceIds($block);
		data.layoutRecordKey = utils.dom.getBlockLayoutKey($block);

	} else {

		data.blockId = utils.dom.getBlockId($block);

	}

	$.ajax({
		method: 'POST',
		url: utils.getUrl('block.loadMore'),
		data: data,
		dataType: 'json'
	}).done(this.onFetchDone.bind(this));

};

LoadMore.prototype.onFetchDone = function(response) {

	var $response = $(response.markup);

	triggerCustomEvent('gallery_items_fetched', {
		target: this.$gallery,
		data: {
			$items: $response.filter('.gallery__item'),
			$thumbs: $response.filter('.gallery__thumb')
		}
	});

};

module.exports = {
	create: create,
	get: get
};
