'use strict';

var giftCard = require('./gift-card/main');
var video = require('./video/main');
var product = require('./product/main');
var service = require('./service/main');
var scheduler = require('./scheduler/main');
var mobileTouch = require('./images/main');
var pinboard = require('./pinboard');
var loadMore = require('./load-more');

var blocks = {
	giftCard: giftCard,
	video: video,
	product: product,
	service: service,
	scheduler: scheduler
};

var init = function() {

	// Possibly init all blocks here, refactor as necessary (need to see how much js is actually needed for the blocks)

	Object.keys(blocks).forEach(function(block) {

		blocks[block].init();

	});

	pinboard.init();
	loadMore.init();
	
	mobileTouch.init();

};

module.exports = {
	init: init
};
