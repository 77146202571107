'use strict';

var appDom = require('../../app-dom');
var mobileTouchStartEventListener = require('./mobile-touchstart-listener');

var init = function() {

	mobileTouchStartEventListener.init();

};

module.exports = {
	init: init
};
